import http from './http';
import { getApiRoute } from '../../config';

export default {
  async getPlayedOutcomes(config, eventId) {
    const results = await http.get(
      `${getApiRoute('ticketApi', config)}/tickets/outcomes/${config.tenantId}/${eventId}`,
    );

    return results.data;
  },
};
