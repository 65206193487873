<template>
  <router-view></router-view>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'App',

  computed: {
    ...mapGetters([
      'isLongerOffline', //
      'getTranslation',
    ]),
  },

  watch: {
    isLongerOffline(newValue) {
      if (newValue) {
        this.setInfoMessage(this.getTranslation('disconnected'));
        this.activateLoader();
        this.activateScreenOverlay();
        this.lockInfoMessage();
      } else {
        this.setInfoMessage('');
        this.deactivateLoader(1000);
        this.deactivateScreenOverlay(1000);
        this.unlockInfoMessage();
      }
    },
  },

  methods: {
    ...mapActions([
      'updateOnlineStatus', //
      'activateLoader',
      'deactivateLoader',
      'activateScreenOverlay',
      'deactivateScreenOverlay',
      'setInfoMessage',
      'lockInfoMessage',
      'unlockInfoMessage',
    ]),
  },

  mounted() {
    this.updateOnlineStatus();
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },

  unmounted() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&family=Roboto:wght@300;400;700&family=Barlow+Condensed:wght@600&display=swap');
*,
*:before,
*:after {
  box-sizing: border-box;
  user-select: none;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background: #000;
  color: #fff;
  overflow: hidden;
}

#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --highlight-color: #fff500;
}
</style>
