import http from './http';
import { getApiRoute } from '../../config';

export default {
  async getPreviousResults(config) {
    const results = await http.get(`${getApiRoute('historyApi', config)}/results/game/${config.productInstanceUuid}`, {
      params: {
        size: 20,
      },
    });

    return results.data;
  },
};
