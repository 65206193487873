export default {
  common: {},
  development: {
    webAppUrl: 'https://dev.crash-cash-visualization.7platform.net/',
    historyApi: 'https://games-crash-cash-event.staging.de-2.nsoft.cloud',
    ticketApi: 'https://games-crash-cash-ticket.staging.de-2.nsoft.cloud',
    webSocketUrl: 'https://crash-cash-websocket.staging.de-2.nsoft.cloud',
  },
  staging: {
    webAppUrl: 'https://staging.crash-cash-visualization.7platform.net/',
    historyApi: 'https://games-crash-cash-event.staging.de-2.nsoft.cloud',
    ticketApi: 'https://games-crash-cash-ticket.staging.de-2.nsoft.cloud',
    webSocketUrl: 'https://crash-cash-websocket.staging.de-2.nsoft.cloud',
  },
  production: {
    webAppUrl: 'https://crash-cash-visualization.7platform.net/',
    historyApi: 'https://games-crash-cash-event.de-2.nsoft.cloud',
    ticketApi: 'https://games-crash-cash-ticket.de-2.nsoft.cloud',
    webSocketUrl: 'https://crash-cash-websocket.de-2.nsoft.cloud',
  },
};
