export default {
  // Debug mode should trigger logging etc.
  debug: false,
  debugWindow: false,
  simulate: false,
  publicPath: process.env.VUE_APP_DESKTOP_BUILD === 'true' ? './' : '/',
  // Game status (reconsider this)
  stateMode: false,
  // Demo mode primarily for sticker info
  demoMode: false,
  gameInitialized: false,
  /* Manually triggered desktop app mode */
  desktopAppMode: false,

  // Connection details
  isOnline: true,
  connectionLostTimestamp: null,
  connectionLostInterval: null,
  connectionLostDuration: 0,
  connectionOfflineLimit: 30,
  connectionReconnectActive: true,
  eventMessagesDisrupted: false, // Packet loss and so on

  // Delivery channel
  deliveryChannel: 'retail',
  channels: ['web', 'mobile', 'retail', 'terminal'],

  // ENV.
  supportedEnvironments: ['dev', 'staging', 'production'],

  // Supported games
  supportedGames: {
    airplane: {
      name: 'airplane',
      fullName: 'Crash Cash',
      title: 'CrashCash',
    },
  },

  gamesMapper: {
    aviator: 'airplane',
  },

  // Active game details
  activeGame: 'airplane',
  activeGameTitle: 'CrashCash',
  subscriptionActive: true,
  customGameLogo: null,

  // Casino Boost widget (payments/winnings info)
  boostEnabled: true,
  boostId: '057d758c-57bb-4c40-9696-00957c5d6cb2', // PF boost tenantId
  sidebarEnabled: true,

  // Desktop app details
  appName: null,
  isDesktopApp: false,
  desktopSystem: null,
  appRunUuid: null,
  systemsSdk: false,
  betshopId: undefined,
  deviceId: undefined,
  sendKeepAliveToRuntimeEnabled: true,

  localAppConfig: {
    // Here goes the local device app config, configured on 7Admin device configuration
  },

  platformConfig: {
    platform: 'seven',
    productName: 'crashcash',
    // ======= staging ========
    environment: 'staging',
    productId: '',
    productInstanceUuid: '',
    tenantId: 'b99752b3-443c-4c80-b559-945a95c4b805',
    // ==========================
    platformDataCenter: '',
    locale: 'en',
    applicationName: 'Web', // delivery channel
  },

  gameConfig: {
    supportedEvents: {
      idle: 'SetIdle',
      SetIdle: 'SetIdle',
      start: 'StartEvent',
      StartEvent: 'StartEvent',
      step: 'SetStep',
      SetStep: 'SetStep',
      crash: 'FinishEvent',
      FinishEvent: 'FinishEvent',
      stop: 'StopGame',
      StopGame: 'StopGame',
      outcomes: 'Outcomes',
      Outcomes: 'Outcomes',
      won_at: 'WonAt',
      wonAt: 'WonAt',
      WonAt: 'WonAt',
      results: 'SetResults',
      SetResults: 'SetResults',
      ConnectionReconnect: 'ConnectionReconnect',
    },
  },

  // Override game config properties (CMS)
  gameConfigOverride: {},

  game: {
    gameEventsActive: false,
    eventId: undefined,
    isValidEventStart: false,
    isMidEventActive: false,
    isEventFinished: false,
    delayBeforeIncrease: 5,
    crashedEventId: undefined,
    currentStage: null,
    displayedStage: null,
    countdownTime: 0,
    currentMultiplier: 1,
    bonusMultiplier: 1,
    bonusEventId: undefined,
    previousResults: [],
    result: null,
    statistics: {},
  },

  isBonusRoundAnnounced: false,
  bonusRoundAnnouncementDuration: 10,
  bonusWheelValues: [],
  bonusWheelSets: {
    1: [3, 4, 3, 6, 3, 4, 3, 6, 3, 4, 3, 6, 3, 4, 3, 6],
    2: [3, 5, 3.5, 4, 3, 2.5, 6, 3.5, 4, 4.5, 3, 11, 5.5, 3, 4, 2.5],
  },
  bonusWheelSet: 2,

  availableDisplayStages: ['StartCountdown', 'StartEvent', 'SetResults'],

  // Display settings
  displaySettings: {
    animations: {
      // list generated from merging full and selected/layout animation set and also individual animations list
    },
    animationSet: 'full', // by default, all animations are enabled
    animationSets: {
      // standard animation sets
      full: {
        multiplierIncrease: true,
        multiplierHighlight: true,
        loaderTransition: true,
        headerEffects: true,
        waterWaves: true,
        propellerOnly: false, // parallax scene
      },
      medium: {
        waterWaves: false,
      },
      basic: {
        multiplierHighlight: false,
        headerEffects: false,
        waterWaves: false,
        propellerOnly: true, // parallax scene
      },
      none: {
        multiplierIncrease: false,
        multiplierHighlight: false,
        loaderTransition: false,
        headerEffects: false,
        waterWaves: false,
        propellerOnly: true, // parallax scene
      },
      // layout based animation sets
      desktop: {},
      web: {
        multiplierIncrease: false,
      },
      mobile: {
        multiplierIncrease: false,
      },
      rpi: {
        multiplierHighlight: false,
        headerEffects: false,
        waterWaves: false,
      },
    },
    quality: null, // low, medium, high
    forceExternalAssets: false,
    forceLocalAssets: false,
  },

  sceneMode: 'threejs',
  availableSceneModes: ['threejs', 'parallax'],

  // Dropping parachutes functionality
  parachutesEnabled: true,
  parachutesMethod: 'socket',
  availableParachuteMethods: ['http', 'socket'],
  parachutesRandomDropping: false,
  playedOutcomes: [],

  // On/off settings, primarily for test stage and performance checkups
  toggleSettings: {
    // Game UI content
    headerEnabled: true,
    gameContentEnabled: true,
    spriteAnimate: true,
    sceneBlurEnabled: true,
    sceneBgTranslate: true,
    propellerOnly: false,
    disableWebGLBonusWheel: false,
    threejsEnabled: true,
    gameEventsEnabled: true,
    sceneBgTexture: false,
    sceneBgModel: true,
    airplaneEnabled: true,
    cloudsModelEnabled: true,
    cloudsGenerateEnabled: false,
    starsEnabled: true,
    waterModelEnabled: false,
    waterGenerateEnabled: false,
    waterGenerateLayersEnabled: true,
    wavesAnimationEnabled: false,
    mountainsEnabled: true,
    extendMountainsEnabled: true,
    mountainHillsEnabled: true,
    rocksAndCactusesEnabled: true,
    ambientLightEnabled: false,
    hemisphereLightEnabled: false,
    sunLightEnabled: true,
    directionalLightEnabled: true,
    performanceInfoEnabled: false,
  },

  // Previous results enable state
  previousResultsEnabled: true,
  // Should be previous results displayed or not
  previousResultsActive: true,

  supportedQualities: {
    high: {
      width: 1920,
      height: 1080,
    },
    medium: {
      width: 1280,
      height: 720,
    },
    low: {
      width: 896,
      height: 504,
    },
    rpi: {
      width: 640,
      height: 360,
    },
    web: {
      width: 640,
      height: 360,
    },
    mobile: {
      width: 360,
      height: 270,
    },
  },

  defaultSettings: {
    quality: {
      desktop: 'medium',
      web: 'low',
      mobile: 'low',
      rpi: 'rpi',
    },
  },

  layoutSettings: {
    desktop: {
      low: {
        width: 896,
        height: 504,
      },
      medium: {
        width: 1280,
        height: 720,
      },
      high: {
        width: 1920,
        height: 1080,
      },
      rpi: {
        width: 640,
        height: 360,
      },
    },
    web: {
      low: {
        width: 360,
        height: 270,
      },
      medium: {
        width: 640,
        height: 360,
      },
      high: {
        width: 896,
        height: 504,
      },
    },
  },

  // Custom assets can be overriden by adding new assets folder crash-cash-assets-airplane-${assetsBranding}
  // The new folder should be added on all web storages (not neccessary inside the project itself)
  // Don't forget setting `forceExternalAssets` flag in that case
  assetsBranding: 'nb', // nb (no branding) for now
  // None customizations at the moment, whitelist the custom folders here when added
  // E.g. if needed custom assets for Prva Firma, set it on CMS as `assetsBranding: 'prvafirma'`
  // And add it also here as available option `['prvafirma']`
  availableCustomAssets: ['santa'],
  // the default value, custom overrides go like airplane-${assetsBranding}
  airplaneFolder: 'crash-cash-assets-airplane-nb',
  defaultAirplaneFolder: 'crash-cash-assets-airplane-nb',

  audioAssetsPreloaded: false,
  imageAssetsPreloaded: false,
  modelAssetsPreloaded: false,
  assetsReady: true,

  // Local HTTP server serving local assets path in order to use it together with external/web link
  localHttpServerEnabled: false,

  // Web storage - image & other assets - Linode S3, CloudFlare R2 & CloudFlare Pages
  storage: 'cloudflarePages', // linode, cloudflare, cloudflarePages

  // Sound settings
  soundSettings: {
    enabled: false,
    muted: true,
    volume: 0.85,
    list: [
      {
        name: 'airplaneIdle',
        src: 'audio/airplane-idle.mp3',
        loop: true,
      },
      {
        name: 'airplaneAccending',
        src: 'audio/airplane-accending.mp3',
      },
      {
        name: 'airplaneFlying',
        src: 'audio/airplane-flying.mp3',
        loop: true,
      },
      {
        name: 'airplaneCrash',
        src: 'audio/airplane-crash.mp3',
      },
      {
        name: 'finalCountdown',
        src: 'audio/final-countdown.mp3',
        volume: 0.4,
      },
    ],
  },

  // Socket & messaging
  socketPostMessageEnabled: true,
  messaging: 'socket',
  messageProtocols: [
    'socket', //
    'pusher',
    'postmessage',
  ],

  // Bus service init state
  busServiceInitialized: false,

  // Layout
  layout: 'desktop',
  baseLayout: null, // requested, non-mapped
  layoutMapper: {
    desktop: 'desktop',
    retail: 'desktop',
    terminal: 'desktop',
    rpi: 'desktop',
    web: 'web',
    mobile: 'web',
    mob: 'web',
  },
  scaleIndex: 1,

  mainBreakpoints: {
    // TODO: Consider adding FHD as available resolution when everything is optimized
    // 1920: {
    //   width: 1920,
    //   height: 1080,
    // },
    1280: {
      width: 1280,
      height: 720,
    },
    1024: {
      width: 1024,
      height: 768,
    },
    896: {
      width: 896,
      height: 504,
    },
    768: {
      width: 768,
      height: 432,
    },
    640: {
      width: 640,
      height: 360,
    },
    480: {
      width: 480,
      height: 320,
    },
    360: {
      width: 360,
      height: 270,
    },
  },

  isLoaderActive: true,
  loaderStartTimeout: null,
  loaderEndTimeout: null,
  infoMessageText: '',
  infoMessageLocked: false,
  screenOverlayActive: false,

  logo: {
    enabled: false,
    path: null,
    image: {
      preferredSize: {
        width: 150,
        height: 25,
      },
      offset: {
        x: 10,
        y: 0,
      },
    },
  },

  // Formatting
  numberFormat: 'european',
  numberFormats: [
    'european', //
    'american',
  ],
  ordinalNumberFormat: 'english', // 'english': 1st, 2nd, 3rd; 'period': 1., 2., 3.
  currency: undefined,
  currencyDisplay: undefined,

  // Catch ULR params here
  urlParams: {},

  stagesMapper: {
    new: 'StartCountdown',
    StartCountdown: 'StartCountdown',
    StartEvent: 'StartEvent',
    bettingDisabled: 'StartEvent',
    results: 'SetResults',
    SetResults: 'SetResults',
    FinishEvent: 'FinishEvent',
    crash: 'FinishEvent',
    SetStep: 'StartEvent',
    step: 'StartEvent',
    SetIdle: 'StartCountdown',
    idle: 'StartCountdown',
  },

  // Visually emphasized last seconds of the countdown
  lastCountdownTime: 11,
  // Countdown doesn't have to begin visually when countdown message comes
  // Some transitions/animations with additional info can be shown
  // before the countdown actually starts
  countdownReady: true,

  // Translations
  translationDomains: ['Visuals', 'GamesCrashCashVisualization'],
  // Use NPM package translations instead of requests to TC/Frasier
  usePackageTranslations: false,
  // Translations bckp (EN) for domain GamesCrashCashVisualization
  translations: {
    productName: 'Crash Cash',
    oddsFrequency: 'Odds frequency',
    currentRound: 'Current round',
    bonusRoundInfo: 'All winnings will be multiplied by drawn number!',
    bonusRoundShortInfo: 'All winnings >>multiplied<<',
    gameplay: 'Gameplay',
    howToPlayInfo: 'How-to info',
    startingIn: 'Starting in',
    roundInProgress: 'Round {{roundNumber}} in progress',
    completedAt: 'Completed at',
    currentOdds: 'Current odds',
    currentPayout: 'Current payout',
    airplaneCrashed: 'Crashed',
    lastBetsInfo: 'Last bets please!',
    multiplierInfo: 'Pick a multiplier you aim to win | until the plane crashes.',
    parachuteInfo: 'Parachute dropped represents that | specific player has finished his bet.',
    crashInfo: 'When the plane crashes, game is over. | If your your aimed multiplier is less | than reached, you win.',
  },
  translateProductName: true,

  // Dynamic settings, for easier track
  setable: {
    appName: 'SET_APP_NAME',
    windowTitle: 'SET_APP_NAME',
    appRunUuid: 'SET_PROP',
    systemsSdk: 'SET_PROP',
    demoMode: 'SET_PROP',
    desktopAppMode: 'SET_PROP',
    localHttpServerEnabled: 'SET_PROP',
    debug: 'SET_PROP',
    debugWindow: 'SET_PROP',
    simulate: 'SET_PROP',
    currency: 'SET_PROP',
    currencyDisplay: 'SET_PROP',
    betshopId: 'SET_PROP',
    deviceId: 'SET_PROP',
    customGameLogo: 'SET_PROP',
    translateProductName: 'SET_PROP',
    socketPostMessageEnabled: 'SET_PROP',
    lastCountdownTime: 'SET_PROP',
    sidebarEnabled: 'SET_PROP',
    parachutesEnabled: 'SET_PROP',
    parachutesMethod: 'SET_PARACHUTES_GET_METHOD',
    parachutesRandomDropping: 'SET_PROP',
    messaging: 'SET_MESSAGE_PROTOCOL',
    layout: 'SET_LAYOUT',
    quality: 'SET_QUALITY',
    forceExternalAssets: 'SET_DISPLAY_SETTINGS',
    forceLocalAssets: 'SET_DISPLAY_SETTINGS',
    assetsBranding: 'SET_ASSETS_BRANDING_FOLDER',
    sceneMode: 'SET_SCENE_MODE',
    channel: 'SET_DELIVERY_CHANNEL',
    type: 'SET_DELIVERY_CHANNEL',
    channelId: 'SET_DELIVERY_CHANNEL',
    deliveryChannel: 'SET_DELIVERY_CHANNEL',
    translations: 'SET_TRANSLATIONS',
    // PLATFORM CONFIG OVERRIDE
    platform: 'SET_CONFIG_PROP',
    productName: 'SET_CONFIG_PROP',
    productId: 'SET_CONFIG_PROP',
    productInstanceUuid: 'SET_CONFIG_PROP',
    tenantId: 'SET_CONFIG_PROP',
    environment: 'SET_ENV',
    env: 'SET_ENV',
    platformDataCenter: 'SET_DC',
    platformDC: 'SET_DC',
    platformDc: 'SET_DC',
    DC: 'SET_DC',
    dc: 'SET_DC',
    locale: 'SET_CONFIG_PROP',
    applicationName: 'SET_CONFIG_PROP',
    storage: 'SET_WEB_STORAGE',
  },
};
