import { isNil, merge } from 'lodash';
import { Howl, Howler } from 'howler';

class AudioManager {
  constructor(settings = {}) {
    this.sounds = {};
    this.settings = {
      enabled: true,
      muted: false,
      volume: 0.85,
    };

    this.updateSettings(settings);
  }

  preload(sounds = []) {
    const promises = sounds.map((sound) => {
      return new Promise((resolve, reject) => {
        this.sounds[sound.name] = new Howl({
          src: sound.src,
          loop: sound.loop || false,
          volume: sound.volume || this.settings.volume,
          type: sound.type || 'default',
          preload: true,
          onload: () => resolve(sound.name),
          onloaderror: (id, error) => reject(error),
        });
      });
    });
    return Promise.all(promises);
  }

  updateSettings(settings = {}) {
    merge(this.settings, settings);
    Howler.mute(this.settings.muted);
  }

  getSound(name) {
    return this.sounds && this.sounds[name];
  }

  play(name) {
    if (!this.settings.enabled) return;

    const sound = this.getSound(name);

    if (sound) {
      if (!sound.playing()) {
        sound.play();
      }
    }
  }

  pause(name) {
    const sound = this.getSound(name);

    if (sound) {
      sound.pause();
    }
  }

  stop(name) {
    if (!isNil(name)) {
      const sound = this.getSound(name);

      if (sound) {
        sound.stop();
      }
    } else {
      Howler.stop();
    }
  }

  fade(name, duration, from, to) {
    const sound = this.getSound(name);

    if (sound) {
      sound.fade(from, to, duration);
    }
  }

  fadeIn(name, duration, level) {
    const sound = this.getSound(name);
    const to = level || 1;

    if (sound) {
      sound.fade(0, to, duration);
    }
  }

  fadeOut(name, duration, level) {
    const sound = this.getSound(name);
    const from = level || sound.volume || 1;

    if (sound) {
      sound.fade(from, 0, duration);
    }
  }

  mute() {
    this.updateSettings({ muted: true });
    Howler.mute(true);
  }

  unmute() {
    this.updateSettings({ muted: false });
    Howler.mute(false);
  }

  setVolume(name, volume = this.settings.volume) {
    const sound = this.getSound(name);

    if (sound) {
      sound.volume(volume);
    }
  }

  setGlobalVolume(volume = this.settings.volume) {
    Howler.volume(volume);
  }
}

export default AudioManager;
