import { createApp } from 'vue';
import { sdkModule } from '@nsftx/games-visualization-sdk/src/store/modules';
import { AudioManager } from '@/utility';
import LocalizedLabel from '@/components/LocalizedLabel';
import App from './App';
import router from './router';
import store from './store';
import VueAnime from './plugins/anime';

const app = createApp(App);

// Register audioManager utility as global
app.config.globalProperties.$AudioManager = new AudioManager();

// SDK general vuex module
store.registerModule('sdk', sdkModule);

// prettier-ignore
app
  .use(router)
  .use(store)
  .use(VueAnime)
  .use(LocalizedLabel)
  .mount('#app');
