import { each, isNil, merge, toString } from 'lodash';
import { getStorageEndpoint } from '@nsftx/games-visualization-sdk/config';
import { toBoolean } from '@nsftx/games-visualization-sdk/src/utility';
import { string, WebGL } from '@/utility';

export default {
  publicPath: (state) => state.publicPath,
  // TODO: Reconsider this fix, Windows & RPi have local files load issues
  assetsPath: (state, getters) =>
    !state.displaySettings.forceLocalAssets &&
    ((!state.isDesktopApp && !getters.isNewDeviceManagement) ||
      ['windows', 'rpi'].includes(state.desktopSystem) ||
      state.displaySettings.forceExternalAssets)
      ? `${getStorageEndpoint(getters.storage)}`
      : `${state.publicPath}assets/`,
  assetsFolder: (state) => state.airplaneFolder,
  assetsBranding: (state) => state.assetsBranding,
  isStateMode: (state) => state.stateMode,
  isDemoMode: (state) => state.demoMode || false,
  isDebugMode: (state) => toBoolean(toString(state.debug)),
  isDebugWindowActive: (state, getters) => toBoolean(toString(state.debugWindow)) && !getters.isDesktopApp,
  isSimulateMode: (state) => toBoolean(toString(state.simulate)),
  isOnline: (state) => state.isOnline,
  isOffline: (state) => !state.isOnline,
  isLongerOffline: (state) => state.connectionLostDuration > state.connectionOfflineLimit,
  connectionLostDuration: (state) => state.connectionLostDuration,
  connectionReconnectActive: (state) => state.connectionReconnectActive,
  eventMessagesDisrupted: (state) => state.eventMessagesDisrupted,
  savedUrlParams: (state) => state.urlParams,
  storage: (state) => state.storage,

  supportedGames: (state) => state.supportedGames,
  activeGame: (state) => state.activeGame,
  activeGameTitle: (state) => state.activeGameTitle,
  gameInitialized: (state) => state.gameInitialized,
  isSubscriptionActive: (state) => state.subscriptionActive,
  gameEventsActive: (state) => state.game.gameEventsActive,

  sidebarEnabled: (state) => state.sidebarEnabled,
  boostEnabled: (state) => state.boostEnabled,
  boostId: (state) => state.boostId,

  busServiceInitialized: (state) => state.busServiceInitialized,
  socketPostMessageEnabled: (state) => state.socketPostMessageEnabled,
  messaging: (state) => state.messaging,
  deliveryChannel: (state) => state.deliveryChannel,
  clientLogo: (state) => state.logo,
  customGameLogo: (state) => state.customGameLogo,

  isDesktopAppMode: (state) => toBoolean(toString(state.desktopAppMode)),
  isDesktopApp: (state, getters) => state.isDesktopApp || getters.isDesktopAppMode || getters.isNewDeviceManagement,
  desktopSystem: (state) => state.desktopSystem,
  isNewDeviceManagement: (state) => !!state.systemsSdk || !!state.appRunUuid,
  appRunUuid: (state) => state.appRunUuid,
  betshopId: (state) => state.betshopId,
  deviceId: (state) => state.deviceId,
  appName: (state) => state.appName,
  gameTitle: (state, getters) => {
    return state.translateProductName &&
      (!isNil(getters.translations.productName) || !isNil(getters.translations.product_name))
      ? getters.getTranslation('productName')
      : 'Crash Cash';
  },
  sendKeepAliveToRuntimeEnabled: (state) => state.sendKeepAliveToRuntimeEnabled,
  environment: (state) => state.platformConfig.environment,

  currentStage: (state) => state.game.currentStage,
  displayedStage: (state) => state.game.displayedStage,
  availableDisplayStages: (state) => state.availableDisplayStages,
  gameData: (state) => state.game,
  isValidEventStart: (state) => state.game.isValidEventStart,
  isMidEventActive: (state) => state.game.isMidEventActive,
  isEventFinished: (state) => state.game.isEventFinished,
  startDelay: (state) => state.game.delayBeforeIncrease,
  currentMultiplier: (state) => state.game.currentMultiplier,
  isBonusRound: (state, getters) => getters.currentStage === 'StartEvent' && state.game?.bonusMultiplier > 1,
  isBonusRoundValid: (state) => state.game.bonusEventId === state.game.eventId,
  isBonusRoundAnnounced: (state, getters) => getters.isBonusRound && state.isBonusRoundAnnounced,
  bonusRoundAnnouncementDuration: (state) => state.bonusRoundAnnouncementDuration,
  bonusMultiplier: (state) => state.game.bonusMultiplier,
  bonusWheelValues: (state) =>
    !isNil(state.bonusWheelValues) && state.bonusWheelValues.length
      ? state.bonusWheelValues
      : state.bonusWheelSets[state.bonusWheelSet] || [],
  bonusWheelSets: (state) => state.bonusWheelSets || {},
  bonusWheelSet: (state) => state.bonusWheelSet || 2,
  result: (state) => state.game.result,
  previousResults: (state) => state.game.previousResults,
  showPreviousResults: (state, getters) =>
    !getters.isWebLayout &&
    state.previousResultsEnabled &&
    state.previousResultsActive &&
    state?.game?.previousResults.length,
  statistics: (state) => state.game.statistics,
  playedOutcomes: (state) => state.playedOutcomes,
  eventId: (state) => state.game.eventId,
  crashedEventId: (state) => state.game.crashedEventId,
  countdownTime: (state) => state.game.countdownTime,
  countdownReady: (state) => state.countdownReady,
  stagesMapper: (state) => state.stagesMapper,

  currency: (state) => state.currency,
  currencyDisplay: (state) => state.currencyDisplay || state.currency,
  numberFormat: (state) => state.numberFormat,
  ordinalNumberFormat: (state) => state.ordinalNumberFormat,

  scaleIndex: (state) => state.scaleIndex,
  layout: (state) => state.layout,
  baseLayout: (state) => state.baseLayout,
  isWebLayout: (state) => state.layout === 'web',
  isDesktopLayout: (state) => state.layout === 'desktop',
  quality: (state) => state.displaySettings.quality,
  supportedQualities: (state) => state.supportedQualities,

  mainBreakpoints: (state) => state.mainBreakpoints,

  isLoaderActive: (state) => state.isLoaderActive,
  infoMessageText: (state) => state.infoMessageText,
  infoMessageLocked: (state) => state.infoMessageLocked,
  screenOverlayActive: (state) => state.screenOverlayActive,
  loaderStartTimeout: (state) => state.loaderStartTimeout,
  loaderEndTimeout: (state) => state.loaderEndTimeout,
  displaySettings: (state) => state.displaySettings,
  animationSet: (state) => state.displaySettings.animationSet,
  animationSets: (state) => state.displaySettings.animationSets,
  animations: (state) => state.displaySettings.animations,
  isAnimationEnabled: (state, getters) => (value1, value2) =>
    getters?.animations?.[value1] || getters?.animations?.[value2] || false,
  audioEnabled: (state, getters) => state.soundSettings.enabled && getters.isWebLayout,
  soundSettings: (state) => state.soundSettings,
  screensDuration: (state) => state.screensDuration,
  lastCountdownTime: (state) => state.lastCountdownTime,
  isFinalCountdown: (state, getters) =>
    getters.currentStage === 'StartCountdown' && getters.countdownTime <= state.lastCountdownTime,
  toggleSettings: (state) => state.toggleSettings,
  sceneModes: (state) => state.availableSceneModes,
  sceneMode: (state) => state.sceneMode,
  isParallaxMode: (state) => state.sceneMode === 'parallax' || !WebGL.isWebGLAvailable(),
  isThreejsMode: (state) => state.sceneMode === 'threejs' && WebGL.isWebGLAvailable(),
  parachutesEnabled: (state) => state.parachutesEnabled,
  parachutesMethod: (state) => state.parachutesMethod,
  parachutesRandomDropping: (state) => state.parachutesRandomDropping,

  platformConfig: (state, getters) => {
    const activeGamePlatformConfig = getters.isSimulateMode
      ? state[getters.activeGame]?.platformConfig?.[getters.environment] || {}
      : {};
    const platformConfig = merge({}, state.platformConfig, activeGamePlatformConfig);

    return platformConfig;
  },
  gameConfig: (state, getters) => {
    const activeGameConfig = state[getters.activeGame].gameConfig || {};
    const gameConfigOverride = state.gameConfigOverride || {};
    const gameConfig = merge({}, state.gameConfig, activeGameConfig, gameConfigOverride);

    return gameConfig;
  },
  assetsReady: (state) => state.assetsReady || true,
  assetsPreloaded: (state, getters) =>
    state.imageAssetsPreloaded && (getters.isParallaxMode || state.modelAssetsPreloaded),
  localAppConfig: (state) => state.localAppConfig,
  localHttpServerEnabled: (state) => state.localHttpServerEnabled,
  translationDomains: (state) => state.translationDomains,
  usePackageTranslations: (state) => state.usePackageTranslations,
  getTranslation:
    (state, getters) =>
    (key, params = []) => {
      // const gameTranslations = state[getters.activeGame].translations || {};
      const gameTranslations = state.translations || {};
      const transKey = getters.usePackageTranslations ? string.camelToSnake(key) : key;
      let trans = gameTranslations[transKey] || gameTranslations[key] || getters['sdk/getTranslation'](key);

      each(params, (value) => {
        trans = trans.replace(/{{[a-zA-Z0-9-_/]*}}/g, value);
      });

      return trans;
    },
  translations: (state, getters) => {
    // const gameTranslations = state[getters.activeGame].translations || {};
    const gameTranslations = state.translations || {};
    const commonTranslations = getters['sdk/translations'];
    const translations = merge({}, commonTranslations, gameTranslations);

    return translations;
  },
};
