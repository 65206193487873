export default {
  SET_PROP: 'SET_PROP',
  SET_OBJ_PROP: 'SET_OBJ_PROP',
  SET_CONFIG_PROP: 'SET_CONFIG_PROP',
  SET_ACTIVE_GAME: 'SET_ACTIVE_GAME',
  SET_APP_NAME: 'SET_APP_NAME',
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
  SAVE_URL_PARAMETERS: 'SAVE_URL_PARAMETERS',
  SET_BUS_INIT_STATE: 'SET_BUS_INIT_STATE',
  INITIALIZE_GAME: 'INITIALIZE_GAME',
  SET_ENV: 'SET_ENV',
  SET_DC: 'SET_DC',
  SET_DESKTOP_APP_DETAILS: 'SET_DESKTOP_APP_DETAILS',
  SET_APP_HEARTBEAT_STATUS: 'SET_APP_HEARTBEAT_STATUS',
  SET_DELIVERY_CHANNEL: 'SET_DELIVERY_CHANNEL',
  SET_LAYOUT: 'SET_LAYOUT',
  SET_QUALITY: 'SET_QUALITY',
  SET_ANIMATIONS: 'SET_ANIMATIONS',
  SET_LOADER_STATE: 'SET_LOADER_STATE',
  SAVE_INFO_MESSAGE_TEXT: 'SAVE_INFO_MESSAGE_TEXT',
  SET_INFO_MESSAGE_LOCK_STATE: 'SET_INFO_MESSAGE_LOCK_STATE',
  SET_SCALE: 'SET_SCALE',
  SET_ONLINE_STATUS: 'SET_ONLINE_STATUS',
  SET_CONNECTION_LOST_TIME: 'SET_CONNECTION_LOST_TIME',
  RESET_CONNECTION_LOST_TIME: 'RESET_CONNECTION_LOST_TIME',
  SET_CONNECTION_OFFLINE_LIMIT: 'SET_CONNECTION_OFFLINE_LIMIT',
  SET_RECONNECT_STATE: 'SET_RECONNECT_STATE',
  SET_MESSAGE_PROTOCOL: 'SET_MESSAGE_PROTOCOL',
  SET_NUMBER_FORMAT: 'SET_NUMBER_FORMAT',
  SET_ORDINAL_NUMBERS_FORMAT: 'SET_ORDINAL_NUMBERS_FORMAT',
  SET_LOGO_DETAILS: 'SET_LOGO_DETAILS',
  SET_STATE_MODE: 'SET_STATE_MODE',
  SET_GAMES_EVENTS_ACTIVE_STATE: 'SET_GAMES_EVENTS_ACTIVE_STATE',
  SET_CURRENT_STAGE: 'SET_CURRENT_STAGE',
  SET_EVENT_STARTED_STATE: 'SET_EVENT_STARTED_STATE',
  SET_EVENT_FINISHED_STATE: 'SET_EVENT_FINISHED_STATE',
  SET_DISPLAYED_STAGE: 'SET_DISPLAYED_STAGE',
  SET_MID_EVENT_STATE: 'SET_MID_EVENT_STATE',
  SET_START_INCREASE_DELAY: 'SET_START_INCREASE_DELAY',
  SET_COUNTDOWN_TIME: 'SET_COUNTDOWN_TIME',
  SET_EVENT_ID: 'SET_EVENT_ID',
  SET_CRASHED_EVENT_ID: 'SET_CRASHED_EVENT_ID',
  SET_CURRENT_MULTIPLIER: 'SET_CURRENT_MULTIPLIER',
  SET_BONUS_MULTIPLIER: 'SET_BONUS_MULTIPLIER',
  SET_BONUS_EVENT_ID: 'SET_BONUS_EVENT_ID',
  SET_BONUS_ROUND_ANNOUNCEMENT_STATUS: 'SET_BONUS_ROUND_ANNOUNCEMENT_STATUS',
  SET_BONUS_ROUND_ANNOUNCEMENT_DURATION: 'SET_BONUS_ROUND_ANNOUNCEMENT_DURATION',
  SET_BONUS_WHEEL_SET: 'SET_BONUS_WHEEL_SET',
  SET_BONUS_WHEEL_VALUES: 'SET_BONUS_WHEEL_VALUES',
  SET_CURRENT_RESULT: 'SET_CURRENT_RESULT',
  SET_PREVIOUS_RESULTS: 'SET_PREVIOUS_RESULTS',
  SET_PREVIOUS_RESULTS_ACTIVATION_STATE: 'SET_PREVIOUS_RESULTS_ACTIVATION_STATE',
  SET_PLAYED_OUTCOMES: 'SET_PLAYED_OUTCOMES',
  APPEND_TO_PLAYED_OUTCOMES: 'APPEND_TO_PLAYED_OUTCOMES',
  REMOVE_FROM_PLAYED_OUTCOMES: 'REMOVE_FROM_PLAYED_OUTCOMES',
  SET_GAME_CONFIG_OVERRIDE: 'SET_GAME_CONFIG_OVERRIDE',
  SET_DISPLAY_SETTINGS: 'SET_DISPLAY_SETTINGS',
  SET_SCENE_MODE: 'SET_SCENE_MODE',
  SET_THREEJS_STATE: 'SET_THREEJS_STATE',
  OVERRIDE_TOOGLE_SETTINGS: 'OVERRIDE_TOOGLE_SETTINGS',
  TOGGLE_EVENT_MESSAGES_DISRUPT_STATE: 'TOGGLE_EVENT_MESSAGES_DISRUPT_STATE',
  SET_SUBSCRIPTION_ACTIVITY: 'SET_SUBSCRIPTION_ACTIVITY',
  OVERRIDE_SOUND_SETTINGS: 'OVERRIDE_SOUND_SETTINGS',
  SET_AUDIO_ASSETS_PRELOADED: 'SET_AUDIO_ASSETS_PRELOADED',
  SET_ASSETS_BRANDING_FOLDER: 'SET_ASSETS_BRANDING_FOLDER',
  SET_IMAGE_ASSETS_PRELOADED: 'SET_IMAGE_ASSETS_PRELOADED',
  SET_MODEL_ASSETS_PRELOADED: 'SET_MODEL_ASSETS_PRELOADED',
  SET_SCREEN_OVERLAY_ACTIVE_STATE: 'SET_SCREEN_OVERLAY_ACTIVE_STATE',
  SET_PARACHUTES_GET_METHOD: 'SET_PARACHUTES_GET_METHOD',
  SET_WEB_STORAGE: 'SET_WEB_STORAGE',
};
