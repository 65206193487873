import { each, has, isNil, isObject, lowerCase, merge } from 'lodash';
import types from './mutationTypes';

export default {
  // General/universal/flat props
  [types.SET_PROP](state, payload) {
    Object.keys(payload).forEach((key) => {
      state[key] = payload[key];
    });
  },
  [types.SET_OBJ_PROP](state, payload) {
    Object.keys(payload).forEach((key) => {
      Object.assign(state[key], payload.key);
    });
  },
  [types.SET_CONFIG_PROP](state, payload) {
    Object.keys(payload).forEach((key) => {
      state.platformConfig[key] = payload[key];
    });
  },
  [types.SET_TRANSLATIONS](state, payload) {
    const translations = payload.translations || {};
    each(translations, (value, key) => {
      if (!isObject(value)) {
        state.translations[key] = value;
      } else if (state.translationDomains.includes(key)) {
        merge(state.translations, value);
      }
    });
  },
  [types.SET_MESSAGE_PROTOCOL](state, payload) {
    Object.keys(payload).forEach((key) => {
      if (state.messageProtocols.includes(lowerCase(payload[key]))) {
        state.messaging = payload[key];
      }
    });
  },
  [types.SAVE_URL_PARAMETERS](state, payload) {
    state.urlParams = payload;
  },
  [types.SET_BUS_INIT_STATE](state) {
    state.busServiceInitialized = true;
  },
  [types.INITIALIZE_GAME](state) {
    state.gameInitialized = true;
  },
  [types.SET_LOADER_STATE](state, value) {
    state.isLoaderActive = value;
  },
  [types.SET_SCREEN_OVERLAY_ACTIVE_STATE](state, value) {
    state.screenOverlayActive = value;
  },
  [types.SET_ENV](state, payload) {
    const env = payload.environment || payload.env;
    if (state.supportedEnvironments.includes(env)) {
      state.platformConfig.environment = env;
    }
  },
  [types.SET_DC](state, payload) {
    state.platformConfig.platformDataCenter =
      payload.platformDataCenter || payload.platformDC || payload.platformDc || payload.DC || payload.dc;
  },
  [types.SET_DELIVERY_CHANNEL](state, payload) {
    Object.keys(payload).forEach((key) => {
      if (state.channels.includes(lowerCase(payload[key]))) {
        state.deliveryChannel = payload[key];
      } else if (state.isDesktopApp || !!state.systemsSdk || !!state.appRunUuid) {
        /**
         * Delivery channel is received from localadmin response as `shop`, `terminal` or `visualization`
         * Those two - shop & visualization - are both representing `retail` delivery channel
         */
        state.deliveryChannel = payload[key] === 'terminal' ? payload[key] : 'retail';
      }
    });
  },
  [types.SAVE_INFO_MESSAGE_TEXT](state, value) {
    state.infoMessageText = value ?? '';
  },
  [types.SET_INFO_MESSAGE_LOCK_STATE](state, value) {
    state.infoMessageLocked = value;
  },
  // Web storage (other assets content)
  [types.SET_WEB_STORAGE](state, payload) {
    const defaultStorage = 'cloudflarePages';

    // There's no validation for storage input cause of easier extending storage options
    // Anyway, SDK will return default option (Linode) for unknown request
    state.storage = payload.storage || defaultStorage;
  },

  // Active game details
  [types.SET_ACTIVE_GAME](state, value) {
    if (!isNil(state.supportedGames[value])) {
      state.activeGame = value;
      state.activeGameTitle = state.supportedGames[value].title;
    }
  },
  [types.SET_SUBSCRIPTION_ACTIVITY](state, value) {
    state.subscriptionActive = value;
  },
  [types.SET_STATE_MODE](state, value) {
    state.stateMode = value;
  },
  [types.SET_GAMES_EVENTS_ACTIVE_STATE](state, value) {
    state.game.gameEventsActive = value;
  },
  [types.SET_GAME_CONFIG_OVERRIDE](state, payload) {
    Object.keys(payload).forEach((key) => {
      state.gameConfigOverride[key] = payload[key];
    });
  },
  [types.SET_CURRENT_STAGE](state, value) {
    state.game.currentStage = value;
  },
  [types.SET_EVENT_STARTED_STATE](state, value) {
    state.game.isValidEventStart = value;
  },
  [types.SET_EVENT_FINISHED_STATE](state, value) {
    state.game.isEventFinished = value;
  },
  [types.SET_MID_EVENT_STATE](state, value) {
    state.game.isMidEventActive = value;
  },
  [types.TOGGLE_EVENT_MESSAGES_DISRUPT_STATE](state, value) {
    state.eventMessagesDisrupted = value;
  },
  [types.SET_DISPLAYED_STAGE](state, value) {
    if (state.availableDisplayStages.includes(value)) {
      state.game.displayedStage = value;
    }
  },
  [types.SET_START_INCREASE_DELAY](state, value) {
    state.game.delayBeforeIncrease = value || 5;
  },
  [types.SET_COUNTDOWN_TIME](state, value) {
    state.game.countdownTime = value;
  },
  [types.SET_EVENT_ID](state, value) {
    state.game.eventId = value;
  },
  [types.SET_CRASHED_EVENT_ID](state, value) {
    if (!isNil(value)) {
      state.game.crashedEventId = value;
    }
  },
  [types.SET_CURRENT_MULTIPLIER](state, value) {
    state.game.currentMultiplier = value || 1;
  },
  [types.SET_BONUS_MULTIPLIER](state, value) {
    state.game.bonusMultiplier = value || 0;
  },
  [types.SET_BONUS_EVENT_ID](state, value) {
    state.game.bonusEventId = value || 0;
  },
  [types.SET_BONUS_ROUND_ANNOUNCEMENT_STATUS](state, value) {
    state.isBonusRoundAnnounced = value;
  },
  [types.SET_BONUS_ROUND_ANNOUNCEMENT_DURATION](state, value) {
    state.bonusRoundAnnouncementDuration = value;
  },
  [types.SET_BONUS_WHEEL_SET](state, value) {
    state.bonusWheelSet = value || 2;
  },
  [types.SET_BONUS_WHEEL_VALUES](state, payload) {
    state.bonusWheelValues = payload || [];
  },
  [types.SET_CURRENT_RESULT](state, payload) {
    state.game.result = payload;
  },
  [types.SET_PREVIOUS_RESULTS](state, payload) {
    state.game.previousResults = payload;
  },
  [types.SET_PLAYED_OUTCOMES](state, payload) {
    state.playedOutcomes = (payload && payload.filter((value) => value > 1)) || [];
  },
  [types.APPEND_TO_PLAYED_OUTCOMES](state, value) {
    if (!state.playedOutcomes.includes(value)) {
      state.playedOutcomes.push(value);
    }
  },
  [types.REMOVE_FROM_PLAYED_OUTCOMES](state, value) {
    state.playedOutcomes.splice(state.playedOutcomes.indexOf(value), 1);
  },

  // Desktop specifics
  [types.SET_APP_NAME](state, payload) {
    const appName = payload.appName || payload.windowTitle;
    state.appName = appName;
    window.document.title = appName;
    state.isDesktopApp = true;
  },
  [types.SET_DESKTOP_APP_DETAILS](state, payload) {
    state.isDesktopApp = payload.isDesktopApp;
    state.desktopSystem = payload.desktopSystem !== 'undefined' ? payload.desktopSystem : null;
  },
  [types.SET_APP_HEARTBEAT_STATUS](state, payload) {
    state.sendKeepAliveToRuntimeEnabled = payload?.enabled || payload;
  },

  // Visual settings
  [types.SET_DISPLAY_SETTINGS](state, payload) {
    each(payload, (value, key) => {
      state.displaySettings[key] = value;
    });
  },
  [types.SET_LAYOUT](state, payload) {
    if (!isNil(payload?.layout) && !isNil(state.layoutMapper?.[payload.layout])) {
      state.layout = state.layoutMapper[payload.layout];
      state.baseLayout = payload.layout;
    } else if (!isNil(state.urlParams?.layout) && !isNil(state.layoutMapper?.[state.urlParams.layout])) {
      state.layout = state.layoutMapper[state.urlParams.layout];
      state.baseLayout = state.urlParams.layout;
    } else {
      // const defaultLayout = state.isDesktopApp ? 'desktop' : 'web';
      const defaultLayout = 'desktop';

      state.layout = state.layoutMapper[defaultLayout];
      state.baseLayout = defaultLayout;
    }
  },
  [types.SET_PREVIOUS_RESULTS_ACTIVATION_STATE](state, value) {
    state.previousResultsActive = value;
  },
  [types.SET_QUALITY](state, payload) {
    function isQualityValid(quality) {
      // NOTE: Disable combination of desktop layout and web/mobile quality
      return (
        !isNil(state.supportedQualities?.[quality]) &&
        (!['web', 'mobile'].includes(quality) || (['web', 'mobile'].includes(quality) && state.layout !== 'desktop'))
      );
    }

    function setDefaultQuality() {
      if (!isNil(state.urlParams?.quality) && isQualityValid(state.urlParams.quality)) {
        state.displaySettings.quality = state.urlParams.quality;
      } else {
        state.displaySettings.quality = state.defaultSettings.quality[state?.layout] || 'medium';
      }
    }

    if (!isNil(payload)) {
      const q = payload.quality || payload;

      if (isQualityValid(q)) {
        state.displaySettings.quality = q;
      } else {
        setDefaultQuality();
      }
    } else if (isNil(state.displaySettings.quality)) {
      setDefaultQuality();
    }
  },
  [types.SET_AUDIO_ASSETS_PRELOADED](state, value) {
    state.audioAssetsPreloaded = value;
  },
  [types.SET_IMAGE_ASSETS_PRELOADED](state, value) {
    state.imageAssetsPreloaded = value;
  },
  [types.SET_MODEL_ASSETS_PRELOADED](state, value) {
    state.modelAssetsPreloaded = value;
  },
  [types.SET_ASSETS_BRANDING_FOLDER](state, payload) {
    const value = payload?.assetsBranding;
    if (!isNil(value) && state.availableCustomAssets.includes(value)) {
      state.assetsBranding = value;
      state.airplaneFolder = `crash-cash-assets-airplane-${value}`;
    } else {
      state.airplaneFolder = state.defaultAirplaneFolder;
      console.warn('UNSUPPORTED BRANDING', value, payload);
    }
  },
  [types.SET_SCENE_MODE](state, payload) {
    const sceneMode = payload?.sceneMode;

    if (sceneMode && state.availableSceneModes.includes(sceneMode)) {
      state.sceneMode = sceneMode;
    }
  },
  [types.SET_SCALE](state, value) {
    state.scaleIndex = value || 1;
  },
  [types.SET_NUMBER_FORMAT](state, value) {
    state.numberFormat = state.numberFormats.includes(value) ? value : 'european';
  },
  [types.SET_ORDINAL_NUMBERS_FORMAT](state, value) {
    state.ordinalNumberFormat = ['period', 'dot'].includes(value) ? 'period' : 'english';
  },
  [types.SET_LOGO_DETAILS](state, payload) {
    merge(state.logo, payload);
  },
  [types.SET_ANIMATIONS](state, payload) {
    if (!isNil(payload.animations)) {
      state.displaySettings.animations = payload.animations;
    }
    if (!isNil(payload.animationSet)) {
      state.displaySettings.animationSet = payload.animationSet;
    }
  },
  // [types.SET_THREEJS_STATE](state, value) {
  //   state.displaySettings.threejsEnabled = value;
  // },
  [types.OVERRIDE_TOOGLE_SETTINGS](state, payload) {
    merge(state.toggleSettings, payload);
  },
  [types.SET_PARACHUTES_GET_METHOD](state, value) {
    if (state.availableParachuteMethods.includes(value)) {
      state.parachutesMethod = value;
    }
  },
  [types.OVERRIDE_SOUND_SETTINGS](state, payload) {
    merge(state.soundSettings, payload);
  },

  // Connection status
  [types.SET_ONLINE_STATUS](state, isOnline) {
    state.isOnline = isOnline;
  },
  [types.SET_CONNECTION_LOST_TIME](state) {
    if (isNil(state.connectionLostTimestamp)) {
      state.connectionLostTimestamp = new Date().getTime();
      state.connectionLostDuration = 0;
      state.connectionLostInterval = setInterval(() => {
        const oldTime = (state.connectionLostTimestamp || new Date().getTime()) / 1000;
        const newTime = new Date().getTime() / 1000;

        state.connectionLostDuration = newTime - oldTime;
      }, 1000);
    }
  },
  [types.RESET_CONNECTION_LOST_TIME](state) {
    clearInterval(state.connectionLostInterval);
    state.connectionLostInterval = null;
    state.connectionLostTimestamp = null;
    state.connectionLostDuration = 0;
  },
  [types.SET_CONNECTION_OFFLINE_LIMIT](state, value = 45) {
    state.connectionOfflineLimit = value;
  },
  [types.SET_RECONNECT_STATE](state, value) {
    state.connectionReconnectActive = value;
  },
};
