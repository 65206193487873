import { isNil, merge, toString } from 'lodash';
import { getQueryParams, toBoolean } from '@nsftx/games-visualization-sdk/src/utility';
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import store from '@/store';

const supportedGames = merge({}, { '': null }, store.state.gamesMapper);

function isNewDeviceManagement() {
  const urlParams = getQueryParams();
  return !isNil(urlParams.systemsSdk) || !isNil(urlParams.appRunUuid);
}

function isDesktopAppMode() {
  const urlParams = getQueryParams();
  return toBoolean(toString(urlParams.desktopAppMode));
}

const historyMode =
  process.env.VUE_APP_DESKTOP_BUILD === 'true' || isDesktopAppMode() || isNewDeviceManagement()
    ? createWebHashHistory()
    : createWebHistory();

const routes = Object.keys(supportedGames).map((key) => {
  return {
    path: `/${key}`,
    component: () => import(/* webpackChunkName: "home" */ '@/pages/Home'),
    props: {
      game: supportedGames[key],
    },
  };
});

const router = createRouter({
  history: historyMode,
  routes,
});

export default router;
