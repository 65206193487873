/*
Use this file to parse and set your configurations.
You can use process.env to get enviroment variables set in env files.
*/
import { isNil } from 'lodash';
import apiRoutes from './routes';

const env = process.env.VUE_APP_ENVIRONMENT || process.env.NODE_ENV;
const routes = Object.assign(apiRoutes.common, apiRoutes[env]);

const getApiRoute = (routeName, config) => {
  if (!isNil(config?.environment)) {
    return apiRoutes[config.environment][routeName];
  }
  return routes[routeName];
};

export { env, routes, getApiRoute };
