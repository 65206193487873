/**
 * Convert camelCaseStrings to snake_case_strings
 */
const camelToSnake = (string) => {
  return string.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
};

/**
 * Remove all whitespaces from string
 */
const removeWhitespaces = (string) => {
  return string.replace(/\s/g, '');
};

export default {
  camelToSnake,
  removeWhitespaces,
};
