import { each, isNil, omit } from 'lodash';
import { BusService } from '@nsftx/games-bus';

let busService = null;

/**
 * Start Bus service instance
 * @param {Object} settings
 * @param {String} settings.game
 * @param {String} settings.environment
 * @param {String} settings.platform
 * @param {String} settings.platformName
 * @param {String} settings.platformDataCenter
 * @param {String} settings.productName
 * @param {String} settings.productId
 * @param {String} settings.tenantId
 * @param {String} settings.locale
 * @param {String} settings.applicationName
 * @param {Boolean} settings.useCommonEventName
 * @param {Array} settings.adapters
 * @param {Array} settings.channels
 */
const init = (settings) => {
  try {
    busService = new BusService(omit(settings, ['channels']));

    each(settings.channels, (channel) => {
      busService.addChannel(channel.name, channel.value, channel.adapter);
    });

    console.log('[CC] Starting Bus service.');
    busService.start();
  } catch (error) {
    console.error('[CC] Bus service error!', error);
  }
};

/**
 * Disconnect adapters during reconnect etc.
 */
const disconnectAdapters = () => {
  try {
    const uwsAdapter = busService.getAdapter('UWSAdapter');
    if (!isNil(uwsAdapter)) {
      uwsAdapter.disconnect();
    }
    const localAdapter = busService.getAdapter('LocalAdapter');
    if (!isNil(localAdapter)) {
      localAdapter.disconnect();
    }
  } catch (error) {
    console.error('Unsuccesfull adapters disconnect', error);
  }
};

/**
 * Get game state from HTTP request
 * @param {String} adapter - Adapter name
 * @returns {Object} Game state
 */
const getState = (adapter) => {
  if (isNil(busService)) return null;

  return busService.getState(adapter);
};

/**
 * Send message to Bus service to
 * @param {string} message - Message/action
 * @param {string} payload - Content/data
 */
const sendMessage = (message = '', payload = {}) => {
  if (!isNil(busService)) {
    busService.sendMessage(message, payload);
  }
};

/**
 * Send manifest to LPS Fetcher service
 * @param {Object} manifest - Assets manifest
 */
const sendManifest = (manifest = {}) => {
  if (!isNil(busService)) {
    busService.sendManifest(manifest);
  }
};

export default {
  disconnect: disconnectAdapters,
  sendMessage,
  sendManifest,
  getState,
  init,
};
