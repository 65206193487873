import { h } from 'vue';

export default {
  install: function install(Vue) {
    Vue.component('LocalizedLabel', {
      name: 'LocalizedLabel',
      functional: true,
      props: [
        // eslint-disable-next-line vue/no-reserved-props
        'class', //
        'trans',
      ],
      render() {
        const key = this.$slots.default ? this.$slots.default[0].text.trim() : this.trans;
        const store = this.$store;
        const translation = store.getters.getTranslation(key) || key;

        return h(
          'span', //
          { class: this.class },
          translation,
        );
      },
    });
  },
};
