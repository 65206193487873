/**
 * Check and return what OS app runs on (rpi is an exception due to simplicity)
 */
/* eslint-disable */
export default function detectOS() {
  const platform = navigator.platform.toLowerCase();
  const userAgent = navigator.userAgent.toLowerCase();

  if (platform.includes('win')) {
    return 'windows';
  } else if (platform.includes('linux')) {
    if (userAgent.includes('arm') || userAgent.includes('raspberry')) {
      return 'rpi';
    }
    return 'linux';
  } else if (platform.includes('mac')) {
    return 'macos';
  } else if (platform.includes('iphone') || platform.includes('ipad')) {
    return 'ios';
  } else if (platform.includes('android')) {
    return 'android';
  }

  return 'unknown';
}
