import { each, find, has, isNil, merge } from 'lodash';
import { documentVisibility, getTimeDelay } from '@nsftx/games-visualization-sdk/src/utility';
import { ticketApi, resultsApi } from '@/api';
import { detectOS, gtag } from '@/utility';
// import store from '@/store';
import types from './mutationTypes';

let countdownInterval = null;
let roundCheckerTimeout = null;
let socketCheckerTimeout = null;
let issueNotificationTimeout = null;
let countdownWorker = null;

export default {
  /**
   * Action that calls active game action first if it exists
   * Otherwise calls common action
   * CAUTION: Don't call the action from same name common action (it will loop itself)
   */
  runAction({ dispatch, state }, { action, payload = null }) {
    const availableActions = this._actions;
    const activeGameAction = `${state.activeGame}/${action}`;
    const commonAction = action;

    if (availableActions[activeGameAction]) {
      dispatch(activeGameAction, payload);
    } else if (availableActions[commonAction]) {
      dispatch(commonAction, payload);
    } else {
      console.log(`[CC] ACTIONS ${commonAction} AND ${activeGameAction} ARE NOT AVAILABLE!`);
    }
  },
  setInfoMessage({ commit, dispatch, getters }, payload) {
    let message = getters.isSubscriptionActive ? payload : getters.getTranslation('gameDeactivated');

    if (!getters.infoMessageLocked) {
      // Don't display messages for web layout (https://app.clickup.com/t/869409zhr?comment=90120049885458)
      if (getters.isWebLayout) {
        message = '';
      }
      dispatch('sdk/setInfoMessage', message, { root: true });
      commit(types.SAVE_INFO_MESSAGE_TEXT, message);
    }
  },
  lockInfoMessage({ commit }) {
    commit(types.SET_INFO_MESSAGE_LOCK_STATE, true);
  },
  unlockInfoMessage({ commit }) {
    commit(types.SET_INFO_MESSAGE_LOCK_STATE, false);
  },
  activateLoader({ commit, dispatch, state }, payload) {
    let { loaderStartTimeout, loaderEndTimeout } = state;
    window.clearTimeout(loaderStartTimeout);
    loaderStartTimeout = null;
    window.clearTimeout(loaderEndTimeout);
    loaderEndTimeout = null;

    const details = !isNil(payload) ? payload : {};
    const start = details.start || 0;
    const end = details.end || null;

    const loaderStart = () => {
      commit(types.SET_LOADER_STATE, true);
    };

    loaderStartTimeout = setTimeout(loaderStart, start);

    if (end !== null) {
      dispatch('deactivateLoader', start + end);
    }

    commit(types.SET_PROP, { loaderStartTimeout, loaderEndTimeout });
  },
  deactivateLoader({ commit, getters, state }, end) {
    let { loaderEndTimeout } = state;
    window.clearTimeout(loaderEndTimeout);
    loaderEndTimeout = null;

    if (getters.assetsPreloaded) {
      loaderEndTimeout = setTimeout(() => {
        commit(types.SET_LOADER_STATE, false);
      }, end || 0);
    }

    commit(types.SET_PROP, { loaderEndTimeout });
  },
  activateScreenOverlay({ commit, dispatch }, payload) {
    commit(types.SET_SCREEN_OVERLAY_ACTIVE_STATE, true);
    if (!isNil(payload?.end)) {
      dispatch('deactivateScreenOverlay', payload.end);
    }
  },
  deactivateScreenOverlay({ commit, getters }, delay) {
    const del = delay || 0;
    setTimeout(() => {
      if (getters.gameEventsActive) {
        commit(types.SET_SCREEN_OVERLAY_ACTIVE_STATE, false);
      }
    }, del);
  },
  setDesktopAppDetails({ commit, getters }) {
    let desktopSystem = process.env.VUE_APP_DESKTOP_SYSTEM;

    if (getters.isDesktopAppMode) {
      desktopSystem = detectOS();
    }

    commit(types.SET_DESKTOP_APP_DETAILS, {
      isDesktopApp: process.env.VUE_APP_DESKTOP_BUILD === 'true',
      desktopSystem,
    });
  },
  updateOnlineStatus({ commit, state }) {
    const isOnline = navigator.onLine;

    if (isOnline) {
      commit(types.RESET_CONNECTION_LOST_TIME);
    } else if (isNil(state.connectionLostTimestamp)) {
      commit(types.SET_CONNECTION_LOST_TIME);
    }

    commit(types.SET_ONLINE_STATUS, isOnline);
  },
  setconnectionOfflineLimit({ commit }, value) {
    commit(types.SET_CONNECTION_OFFLINE_LIMIT, value);
  },
  setActiveGame({ commit }, value) {
    commit(types.SET_ACTIVE_GAME, value);
  },
  setLayout({ commit }, payload) {
    commit(types.SET_LAYOUT, payload);
  },
  setQuality({ commit }, payload) {
    commit(types.SET_QUALITY, payload);
  },
  setScale({ commit }, value) {
    commit(types.SET_SCALE, value);
  },
  setAnimations({ commit }, payload) {
    commit(types.SET_ANIMATIONS, payload);
  },
  saveUrlParams({ commit }, payload) {
    commit(types.SAVE_URL_PARAMETERS, payload);
  },
  handleCommonProperties({ commit, state }, payload) {
    Object.keys(payload).forEach((key) => {
      if (has(state.setable, key)) {
        commit(state.setable[key], { [key]: payload[key] });
      }
    });
  },
  handleCmsOverrides({ commit, dispatch, state }, payload) {
    each(payload, (value, key) => {
      switch (key) {
        case 'socketPostMessageEnabled':
        case 'boostEnabled':
        case 'boostId':
        case 'previousResultsEnabled':
        case 'demoMode':
        case 'sidebarEnabled':
        case 'parachutesEnabled':
        case 'parachutesRandomDropping':
        case 'translateProductName':
        case 'customGameLogo':
        case 'localHttpServerEnabled':
          commit(types.SET_PROP, { [key]: payload[key] });
          break;
        case 'parachutesMethod':
          commit(types.SET_PARACHUTES_GET_METHOD, value);
          break;
        case 'numberFormat':
          commit(types.SET_NUMBER_FORMAT, value);
          break;
        case 'ordinalNumberFormat':
          commit(types.SET_ORDINAL_NUMBERS_FORMAT, value);
          break;
        case 'logo':
          commit(types.SET_LOGO_DETAILS, value);
          break;
        case 'appHeartbeat':
        case 'appHeartbeatEnabled':
        case 'sendKeepAliveToRuntimeEnabled':
          commit(types.SET_APP_HEARTBEAT_STATUS, value);
          break;
        case 'connectionOfflineLimit':
          commit(types.SET_CONNECTION_OFFLINE_LIMIT, value);
          break;
        case 'forceExternalAssets':
        case 'forceLocalAssets':
          dispatch('setDisplaySettings', { [key]: value });
          break;
        case 'assetsBranding':
          commit(types.SET_ASSETS_BRANDING_FOLDER, { [key]: value });
          break;
        case 'toggleSettings':
          commit(types.OVERRIDE_TOOGLE_SETTINGS, value);
          break;
        case 'storage':
          // URL param has higher priority than CMS config setting
          // There's no validation for storage input cause of easier extending storage options
          // Anyway, SDK will return default option (Linode) for unknown request
          if (isNil(state.urlParams?.storage)) {
            commit(types.SET_WEB_STORAGE, { storage: value });
          }
          break;
        case 'soundSettings':
          dispatch('overrideSoundSettings', value);
          break;
        default:
          break;
      }
    });

    if (this._actions[`${state.activeGame}/handleCmsOverrides`]) {
      dispatch(`${state.activeGame}/handleCmsOverrides`, payload);
    }
  },
  // overrideAssetsConfig({ commit }, payload) {
  //   commit(types.SET_ASSETS_OVERRIDE, payload);
  // },
  overrideGameConfig({ commit }, payload) {
    commit(types.SET_GAME_CONFIG_OVERRIDE, payload);
  },
  handleGameProperties({ commit, state }, payload) {
    each(payload, (value, key) => {
      if (has(state.setable, key)) {
        commit(types[state.setable[key]], { [key]: value });
      }
      if (key === 'localConfig') {
        commit(types.SET_PROP, { localAppConfig: value });
        each(value, (val, k) => {
          if (has(state.setable, k)) {
            commit(types[state.setable[k]], { [k]: val });
          }
        });
        // TODO: Consider if possible to whitelist whole registered local config instead
        // of adding separately each other when the new one is created
        each(value?.rawData, (v, x) => {
          if (has(state.setable, x)) {
            commit(types[state.setable[x]], { [x]: v });
          }
        });
      }
    });
  },
  overrideSoundSettings({ commit, state }, payload = {}) {
    const highPriorSoundSettings = {};

    // URL params and local app settings have higher priority
    if (!isNil(state.urlParams.soundEnabled)) {
      highPriorSoundSettings.enabled = state.urlParams.soundEnabled === 'true';
    }

    if (!isNil(state.urlParams.soundMuted)) {
      console.log('[CC] URL sound muted', state.urlParams.soundMuted);
      highPriorSoundSettings.muted = state.urlParams.soundMuted === 'true';
    }

    if (!isNil(state.localAppConfig) && !isNil(state.localAppConfig.sound)) {
      highPriorSoundSettings.enabled = state.localAppConfig.sound === 'true';
    }

    const soundSettings = merge({}, payload, highPriorSoundSettings);
    console.log('[CC] override sound settings, payload:', payload, 'finalSettings:', soundSettings);
    commit(types.OVERRIDE_SOUND_SETTINGS, soundSettings);
  },
  initializeGame({ dispatch, commit }) {
    // Consider to delete this
    dispatch('runAction', { action: 'initialize' });
    dispatch('setStateMode');
    commit(types.INITIALIZE_GAME);
  },
  handleBusMessages({ dispatch, getters, state }, payload) {
    const { activeGame } = state;
    if (!getters.toggleSettings.gameEventsEnabled) {
      console.log('[CC] Game events muted.');
      return;
    }
    // const gameState = getters[`${activeGame}/state`];
    const gameConfig = getters[`${activeGame}/gameConfig`] || getters.gameConfig || {};
    const { supportedEvents } = gameConfig;
    const eventData = payload || {};
    const eventType = eventData.type;
    // const eventTime = eventData.time;
    const eventId = eventData?.displayEventId || eventData?.data?.displayEventId;
    // const sentTime = eventData.sentTime || Date.now();

    // Filter supported events
    let eventName = eventData.eventName || eventData.rawEventName;

    if (eventName in supportedEvents) {
      eventName = supportedEvents[eventName];
      if (eventName !== 'ConnectionReconnect') {
        // For mobile phones, the visualization depends on the parent frame and forwarded socket messages.
        // The point is the parent frame quickly after locking the device goes into a reconnect state and
        // the messages don't come to visualization for some time and that's the reason of "visual gap",
        // when nothing happens until the next successful reconnect and new coming messages.
        // The visualization should improve validation and activate the loader in case of inactivity
        // (Crash Cash has frequent messages and any disruption can be considered that issues exist).
        let del = 4000;
        if (eventName === 'StartEvent') {
          del = 6000;
          if (eventData?.data?.bonus?.luckyMultiplier) {
            del += 10000;
          }
        }
        dispatch('setGamesEventsActiveState', true);
        window.clearTimeout(socketCheckerTimeout);
        socketCheckerTimeout = setTimeout(() => {
          dispatch('setGamesEventsActiveState', false);
        }, del);
      }
    } else return;

    if (eventName === 'state' && isNil(eventType)) {
      // Prevent issues
      return;
    }

    if (eventId) {
      dispatch('runAction', { action: 'setRoundNumber', payload: eventId });
    }

    if (has(eventData, 'previousResults')) {
      dispatch('setPreviousResults', eventData.previousResults);
    }

    if (has(eventData, 'statistics')) {
      // dispatch('setWinnerStats', eventData.statistics);
    }
    // RESULTS MESSAGE GETS INSTANTLY AFTER CRASH MESSAGE,
    // TO SHOW CRASH ANIMATION WE NEED TO PROLONG THE STAGE CHANGE
    // THIS IS HANDLED IN SET-RESULTS ACTION
    if (eventName !== 'SetResults') {
      dispatch('runAction', { action: 'setCurrentStage', payload: eventType || eventName });
    }

    switch (eventName) {
      case 'StartEvent':
      case 'SetIdle':
      case 'SetStep':
      case 'FinishEvent':
        dispatch('runAction', { action: `handleMessage_${eventName}`, payload: eventData.data });
        break;
      case 'StopGame':
        dispatch('handleInactiveSubscriptionState');
        break;
      case 'ConnectionReconnect':
        dispatch('setReconnectState', true);
        break;

      case 'ConnectionLost':
      case 'ConnectionSuccess':
        dispatch('setReconnectState', false);

        break;
      case 'Outcomes':
      case 'WonAt':
        dispatch('handleMessage_Outcomes', eventData.data);
        break;
      case 'results':
      case 'SetResults':
        dispatch('handleMessage_SetResults', eventData.data);
        break;
      default:
        break;
    }
  },
  setBusInitState({ commit }) {
    commit(types.SET_BUS_INIT_STATE);
  },
  setGamesEventsActiveState({ commit }, value) {
    commit(types.SET_GAMES_EVENTS_ACTIVE_STATE, value);
  },
  setStateMode({ commit }, delay = 2000) {
    commit(types.SET_STATE_MODE, true);
    setTimeout(() => {
      commit(types.SET_STATE_MODE, false);
    }, delay);
  },
  handleMessage_SetState({ dispatch, getters }, payload) {
    console.log('[CC] handleMessage_SetState', payload);
    const eventData = payload;
    const eventType = eventData.type;
    const eventTime = eventData.time;
    const sentTime = eventData.sentTime || Date.now();
    dispatch('setStateMode');
    dispatch('setCurrentStage', eventType);
    dispatch('setCountdownReadyState', true);

    if (['new', 'bettingDisabled'].includes(eventType)) {
      const delay = eventData.delay || (eventData.data && eventData.data.delay) || 0;
      const countdownTime = getTimeDelay(eventTime, sentTime, delay);
      dispatch('setCountdownTime', countdownTime);
      dispatch('setCountdownInterval');
      dispatch('setInfoMessage', '');
    } else if (eventType === 'results') {
      dispatch('setInfoMessage', getters.getTranslation('roundFinished', [getters.eventId]));
    }
  },
  handleMessage_StartCountdown({ dispatch }, payload) {
    dispatch('toggleEventStartedState', false);
    dispatch('deactivateMessageChecker');
    dispatch('setCountdownTime', payload.countdown || 0);
    dispatch('setCountdownInterval');
    dispatch('unlockInfoMessage');
    dispatch('setInfoMessage', '');
    dispatch('setCurrentMultiplier', 1);
    dispatch('setBonusMultiplier', 1);
    dispatch('setBonusRoundAnnouncementStatus', false);
    // If nothing comes 30 sec. after countdown, something is wrong
    dispatch('activateRoundChecker', payload.countdown > 60 ? payload.countdown + 30 : 90);
  },
  handleMessage_StartEvent({ dispatch, getters }, payload) {
    if (!isNil(payload.delayBeforeIncrease)) {
      dispatch('setStartDelay', payload.delayBeforeIncrease);
    }
    dispatch('setMidEventState', false);
    dispatch('setEventFinishedState', null);
    dispatch('setSubscriptionActivityState', true);
    dispatch('toggleEventStartedState', true);
    dispatch('deactivateMessageChecker');
    dispatch('setCurrentMultiplier', 1);
    dispatch('setBonusRoundDetails', payload);
    dispatch('setResults', null);
    dispatch('setCountdownTime', 0);
    dispatch('setPreviousResultsActivationState', false);
    if (getters.parachutesEnabled && getters.parachutesMethod === 'http') {
      dispatch('getAndSetPlayedOutcomes', payload?.eventId);
    }
    // The flight can last for about 3:30 min., if nothing comes after that time, something is wrong
    dispatch('activateRoundChecker', 220);
  },
  handleMessage_SetResults({ dispatch, getters }, payload) {
    dispatch('setPreviousResults', payload);
    dispatch('deactivateMessageChecker');
    dispatch('unlockInfoMessage');
    dispatch('setInfoMessage', getters.getTranslation('roundFinished', [getters.eventId]));
    dispatch('setCountdownTime', 0);
    if (!getters.isWebLayout) {
      setTimeout(() => {
        dispatch('runAction', { action: 'setCurrentStage', payload: 'SetResults' });
        dispatch('setCountdownReadyState', true);
      }, 3000);
      setTimeout(() => {
        dispatch('setPreviousResultsActivationState', true);
      }, 3500);
    }
    dispatch('setBonusRoundAnnouncementStatus', false);
  },
  handleMessage_SetIdle({ dispatch, getters }, payload) {
    dispatch('setSubscriptionActivityState', true);
    dispatch('toggleEventStartedState', false);
    dispatch('deactivateMessageChecker');
    dispatch('validateAndHandleCountdownTime', payload);
  },
  handleMessage_Outcomes({ dispatch, getters }, payload) {
    if (getters.parachutesEnabled && getters.parachutesMethod === 'socket') {
      if (payload && payload.outcomes) {
        dispatch('setPlayedOutcomes', payload.outcomes);
      } else if (payload && payload.odds) {
        dispatch('appendToPlayedOutcomes', payload.odds);
      }
    }
  },
  validateAndHandleCountdownTime({ dispatch, getters }, payload) {
    // The Crash countdown comes as event every second (in ms format)
    if (payload.time) {
      const timeToSec = Math.round(payload.time / 1000);
      const currentCountdownTime = getters.countdownTime;
      const timeDiffer = Math.abs(currentCountdownTime - timeToSec);
      // Check the countdown and start the new one if it doesn't exist or differs more than 2 sec.
      if (getters.countdownReady && timeToSec > 1 && (!currentCountdownTime || timeDiffer >= 2)) {
        dispatch('handleMessage_StartCountdown', { countdown: timeToSec });
      }
    } else {
      console.log('[CC] Something went wrong with countdown time', payload);
    }
  },
  handleMessage_SetStep({ dispatch, getters }, payload) {
    // This serves instead of classic state event
    if (!getters.isValidEventStart || !documentVisibility.isVisible()) {
      if (!getters.isParallaxMode) {
        dispatch('setMidEventState', true);
      } else {
        const infoMessage = getters.getTranslation('roundInProgress', [payload.displayEventId || '']);

        dispatch('setInfoMessage', infoMessage);
        dispatch('activateLoader');
        if (getters.isWebLayout) {
          dispatch('activateScreenOverlay', { message: infoMessage });
        }
        dispatch('lockInfoMessage');
      }
    }
    dispatch('activateMessageChecker');
    dispatch('setCurrentMultiplier', payload?.multiplier || 1);
    dispatch('setResults', null);
    dispatch('setEventFinishedState', null);
    dispatch('setCountdownTime', 0);
  },
  handleMessage_FinishEvent({ dispatch, getters }, payload) {
    dispatch('deactivateMessageChecker');
    dispatch('setMidEventState', false);
    dispatch('setEventFinishedState', payload);
    setTimeout(() => {
      dispatch('setResults', payload);
    }, 50);
    // dispatch('setBonusMultiplier', 1);
    dispatch('setCountdownTime', 0);

    if (!getters.isWebLayout) {
      dispatch('setCountdownReadyState', false);
    }
  },
  handleInactiveSubscriptionState({ commit, dispatch, getters }) {
    const delay = documentVisibility.isVisible() ? 10000 : 0;
    setTimeout(() => {
      commit(types.SET_SUBSCRIPTION_ACTIVITY, false);
      dispatch('setInfoMessage', getters.getTranslation('gameDeactivated'));
      dispatch('activateLoader');
      dispatch('activateScreenOverlay');
      dispatch('lockInfoMessage');
    }, delay);
  },
  setSubscriptionActivityState({ commit, dispatch }, value) {
    const subscriptionActive = value;
    commit(types.SET_SUBSCRIPTION_ACTIVITY, subscriptionActive);
    if (!subscriptionActive) {
      dispatch('handleInactiveSubscriptionState');
    } else {
      dispatch('setInfoMessage', '');
    }
  },
  setReconnectState({ commit }, value) {
    commit(types.SET_RECONNECT_STATE, value);
  },
  activateRoundChecker({ dispatch }, value) {
    const delay = (value || 120) * 1000;

    window.clearTimeout(roundCheckerTimeout);
    roundCheckerTimeout = window.setTimeout(() => {
      // If this happens, it means that the game events don't work properly
      if (navigator.onLine) {
        dispatch('setReconnectState', true);
      } else {
        dispatch('activateRoundChecker', 60);
      }
    }, delay);
  },
  activateMessageChecker({ commit, dispatch, getters }) {
    dispatch('deactivateMessageChecker');

    issueNotificationTimeout = setTimeout(() => {
      commit(types.TOGGLE_EVENT_MESSAGES_DISRUPT_STATE, true);
      dispatch('setInfoMessage', getters.getTranslation('problemInCommunication'));
      if (!getters.isLoaderActive) {
        dispatch('activateLoader');
      }
      dispatch('activateScreenOverlay');
    }, 10000);
  },
  deactivateMessageChecker({ commit, dispatch, getters }) {
    clearTimeout(issueNotificationTimeout);
    issueNotificationTimeout = null;
    commit(types.TOGGLE_EVENT_MESSAGES_DISRUPT_STATE, false);
    if (!getters.infoMessageLocked && !getters.isStateMode) {
      if (getters.isLoaderActive && !getters.loaderEndTimeout) {
        dispatch('deactivateLoader', 2000);
      }
      if (documentVisibility.isVisible()) {
        dispatch('deactivateScreenOverlay', 1500);
      }
      dispatch('setInfoMessage', '');
    }
  },
  setRoundNumber({ commit, getters }, value) {
    const currentEventId = getters.eventId;

    if (currentEventId && currentEventId === value) return;

    commit(types.SET_EVENT_ID, value);
  },
  setCurrentStage({ commit, getters }, value) {
    const { currentStage, displayedStage } = getters;
    const newStage = getters.stagesMapper[value];
    if (!isNil(newStage) && (newStage !== currentStage || newStage !== displayedStage)) {
      commit(types.SET_CURRENT_STAGE, newStage);
    }
  },
  setDisplayedStage({ commit, getters }, value) {
    // Prevent setting the same stage again and triggering unneeded history request
    if (getters.displayedStage === value) return;

    commit(types.SET_DISPLAYED_STAGE, value);
    // This done 'cause the results event doesn't exist
    // So the displayed content triggers needed info
    // Otherwise, the current result would be displayed as previous
    // TODO REMOVE THIS AFTER RESULTS THROUGH SOCKET IS RELEASED TO PRODUCTION
    // if (['StartCountdown', 'StartEvent'].includes(value) && !getters.isWebLayout) {
    //   const allowedDelay = {
    //     StartCountdown: 2000,
    //     StartEvent: 4000,
    //   };

    //   setTimeout(() => {
    //     dispatch('getAndSetPreviousResults');
    //   }, Math.random() * allowedDelay[value]);
    // }
  },
  toggleEventStartedState({ commit }, value) {
    commit(types.SET_EVENT_STARTED_STATE, value);
  },
  setMidEventState({ commit }, value) {
    commit(types.SET_MID_EVENT_STATE, value);
  },
  setEventFinishedState({ commit }, payload) {
    commit(types.SET_EVENT_FINISHED_STATE, !isNil(payload));
    commit(types.SET_CRASHED_EVENT_ID, payload?.displayEventId || payload?.displayId);
  },
  setCurrentMultiplier({ commit }, value) {
    commit(types.SET_CURRENT_MULTIPLIER, value);
  },
  setBonusMultiplier({ commit }, value) {
    commit(types.SET_BONUS_MULTIPLIER, value);
  },
  setBonusEventId({ commit }, value) {
    commit(types.SET_BONUS_EVENT_ID, value);
  },
  setBonusRoundAnnouncementStatus({ commit }, value) {
    commit(types.SET_BONUS_ROUND_ANNOUNCEMENT_STATUS, value);
  },
  setBonusRoundAnnouncementDuration({ commit }, value) {
    commit(types.SET_BONUS_ROUND_ANNOUNCEMENT_DURATION, value);
  },
  // This serves for testing purposes, multipliers are coming from backend
  setBonusWheelSet({ commit }) {
    const wheelMultipliers = [2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 11];

    function isSubset(subset, arr) {
      return subset.every((value) => arr.includes(value));
    }

    function doArraysMatch(arr1, arr2) {
      return isSubset(arr1, arr2) && isSubset(arr2, arr1);
    }

    each(this.bonusWheelSets, (value, key) => {
      if (doArraysMatch(value, wheelMultipliers)) {
        commit(types.SET_BONUS_WHEEL_SET, key);
      }
    });
  },
  setBonusWheelValues({ commit }, payload) {
    commit(types.SET_BONUS_WHEEL_VALUES, payload);
  },
  setBonusRoundDetails({ dispatch }, payload) {
    if (!isNil(payload?.luckyMultiplier) || !isNil(payload?.bonus?.luckyMultiplier)) {
      dispatch('setBonusMultiplier', payload?.luckyMultiplier || payload?.bonus?.luckyMultiplier || 1);
      dispatch('setBonusEventId', payload?.displayEventId || payload?.data?.displayEventId);
    }
    if (!isNil(payload?.bonus?.wheelValues)) {
      dispatch('setBonusWheelValues', payload.bonus.wheelValues);
    }
    if (!isNil(payload?.bonus?.bonusWheeTurnDuration)) {
      dispatch('setBonusRoundAnnouncementDuration', payload.bonus.bonusWheeTurnDuration);
    }
  },
  setResults({ commit }, payload) {
    commit(types.SET_CURRENT_RESULT, payload?.multiplier);
    commit(types.SET_CRASHED_EVENT_ID, payload?.displayEventId || payload?.displayId);
  },
  setPreviousResults({ commit }, payload) {
    commit(types.SET_PREVIOUS_RESULTS, payload);
  },
  setPreviousResultsActivationState({ commit }, value) {
    commit(types.SET_PREVIOUS_RESULTS_ACTIVATION_STATE, value);
  },
  getAndSetPreviousResults({ dispatch, getters }) {
    // Don't waste resources on web/mobile since the layout doesn't includes previous rounds
    if (!getters.isDesktopLayout) {
      return;
    }
    const isLastRoundResultsAvailable = () => {
      if (getters.eventId && getters.previousResults.length) {
        const lastRoundId = (getters.eventId - 1).toString();
        const lastRoundItem = find(getters.previousResults, { displayId: lastRoundId });

        return !isNil(lastRoundItem);
      }

      return false;
    };

    // Don't do the request if the valid previous results are already available
    if (!isLastRoundResultsAvailable() || getters.isLongerOffline) {
      resultsApi.getPreviousResults(getters.platformConfig).then((response) => {
        dispatch('setPreviousResults', response.slice(0, 10));
      });
    }
  },
  getAndSetPlayedOutcomes({ dispatch, getters }, value) {
    if (getters.parachutesMethod !== 'http') return;

    ticketApi.getPlayedOutcomes(getters.platformConfig, value).then((response) => {
      dispatch('setPlayedOutcomes', response);
    });
  },
  setPlayedOutcomes({ commit }, payload) {
    commit(types.SET_PLAYED_OUTCOMES, payload);
  },
  appendToPlayedOutcomes({ commit }, payload) {
    commit(types.APPEND_TO_PLAYED_OUTCOMES, payload);
  },
  removeFromPlayedOutcomes({ commit }, value) {
    commit(types.REMOVE_FROM_PLAYED_OUTCOMES, value);
  },
  setCountdownReadyState({ commit }, value) {
    commit(types.SET_PROP, { countdownReady: value });
  },
  setCountdownTime({ commit }, value) {
    const countdownTime = !isNil(value) && value >= 0 ? value : 0;
    commit(types.SET_COUNTDOWN_TIME, countdownTime);
  },
  setCountdownInterval({ dispatch, state }) {
    if (countdownInterval) clearInterval(countdownInterval);
    if (window.Worker) {
      if (!isNil(countdownWorker)) {
        countdownWorker.terminate();
      }
      countdownWorker = new Worker(`${state.publicPath}worker-countdown.js`);
      countdownWorker.postMessage(state.game.countdownTime - 1);

      countdownWorker.onmessage = (e) => {
        const time = e.data || 0;

        dispatch('setCountdownTime', time);
        if (time <= 0) {
          countdownWorker.terminate();
        }
      };
    } else {
      countdownInterval = setInterval(() => {
        let time = state.game.countdownTime - 1;

        if (countdownInterval && state.game.countdownTime <= 0) {
          clearInterval(countdownInterval);
          time = 0;
        }

        dispatch('setCountdownTime', time);
      }, 1000);
    }
  },
  setStartDelay({ commit }, value) {
    commit(types.SET_START_INCREASE_DELAY, value);
  },
  setDisplaySettings({ commit }, payload) {
    commit(types.SET_DISPLAY_SETTINGS, payload);
  },
  setAudioAssetsPreloaded({ commit }) {
    commit(types.SET_AUDIO_ASSETS_PRELOADED, true);
  },
  setImageAssetsPreloaded({ commit }) {
    commit(types.SET_IMAGE_ASSETS_PRELOADED, true);
  },
  setModelAssetsPreloaded({ commit }) {
    commit(types.SET_MODEL_ASSETS_PRELOADED, true);
  },
  generateAssetsConfig({ commit, getters, state }) {
    // const activeGameAssetsConfig = state[getters.activeGame].assetsConfig || {};
    // const assetsConfig = merge({}, state.assetsConfig, activeGameAssetsConfig, state.assetsOverride);
    // commit(types.SET_ASSETS_CONFIG, assetsConfig);
  },
  /**
   * Remove non-webgl screen content for debug purposes
   */
  removeScreenUiContent({ commit, getters, state }) {
    commit(types.OVERRIDE_TOOGLE_SETTINGS, {
      headerEnabled: false,
      gameEventsEnabled: false,
      gameContentEnabled: false,
      sceneBlurEnabled: false,
    });
  },
  /**
   * Revert screen content removed for debug purposes
   */
  revertScreenUiContent({ commit, getters, state }) {
    commit(types.OVERRIDE_TOOGLE_SETTINGS, {
      headerEnabled: true,
      gameEventsEnabled: true,
      gameContentEnabled: true,
      sceneBlurEnabled: true,
    });
  },
  handleToggleSettings({ commit }, payload) {
    commit(types.OVERRIDE_TOOGLE_SETTINGS, payload);
  },
  setupGtag({ getters }) {
    // TODO: Provide those arguments in future only if needed per company level
    // For client, it's provided thorugh Static and visualization doesn't use it
    const { code, auth, preview } = {};

    gtag.setup(code, auth, preview);
  },
};
